import { fabric } from 'fabric'

jQuery( document ).ready(function($) {
  
  // add to setlist
  $('.album .track input').on( "change", function() {
    const content = $(this).parent().html()
    const key = $(this).siblings('label').html()
    const allCurrentTracks = document.querySelectorAll('.setlist li')
    if($(this).parent().hasClass('checked')){
      allCurrentTracks.forEach(element => {
        if($(element).find('label').html() === key){
          $(element).remove()
        }
      });
      $(this).parent().removeClass('checked')
    }else{
      $('.setlist').append(`<li class="track checked" draggable="true"><div class="order"><button class="up"><i class="fa-solid fa-chevron-up"></i></button><button class="down"><i class="fa-solid fa-chevron-down"></i></button></div>${content}</li>`)
      $(this).parent().addClass('checked')
    }

    $('.setlist .track input').on( "change", function() {
      const key = $(this).siblings('label').html()
      const allCurrentTracks = document.querySelectorAll('.setlist li')
      const allAlbumTracks = document.querySelectorAll('.album .track')
      if($(this).parent().hasClass('checked')){
        allCurrentTracks.forEach(element => {
          if($(element).find('label').html() === key){
            // remove from setlist
            $(element).remove()
            // uncheck in album list
            allAlbumTracks.forEach(element => {
              if($(element).find('label').html() === key){
                $(element).removeClass('checked')
                $(element).find('input').prop('checked', false);
              }
            });
          }
        });
        $(this).parent().removeClass('checked')
      }else{
        $(this).parent().addClass('checked')
      }
    })
  })

  sortSetlist()

  // Sort setlist
  function sortSetlist() {
    const sortableList = document.getElementById("sortable");
    let draggedItem = null;
  
    sortableList.addEventListener(
    "dragstart",
    (e) => {
        draggedItem = e.target;
        setTimeout(() => {
            e.target.style.display =
                "none";
        }, 0);
    });
  
    sortableList.addEventListener(
    "dragend",
    (e) => {
        setTimeout(() => {
            e.target.style.display = "";
            draggedItem = null;
        }, 0);
    });
  
    sortableList.addEventListener(
    "dragover",
    (e) => {
        e.preventDefault();
        const afterElement =
            getDragAfterElement(
                sortableList,
                e.clientY);
        const currentElement =
            document.querySelector(
                ".dragging");
        if (afterElement == null) {
            sortableList.appendChild(
                draggedItem
            );} 
        else {
            sortableList.insertBefore(
                draggedItem,
                afterElement
            );}
    });
  
    const getDragAfterElement = (
    container, y
    ) => {
    const draggableElements = [
        ...container.querySelectorAll(
            "li:not(.dragging)"
        ),];
  
    return draggableElements.reduce(
        (closest, child) => {
            const box =
                child.getBoundingClientRect();
            const offset =
                y - box.top - box.height / 2;
            if (
                offset < 0 &&
                offset > closest.offset) {
                return {
                    offset: offset,
                    element: child,
                };} 
            else {
                return closest;
            }},
        {
            offset: Number.NEGATIVE_INFINITY,
        }
    ).element;
    };
  }

  // Get Setlist
  $('#setlistStart').click(function(e){
    const setList = []
    e.preventDefault()
    const allTracks = document.querySelectorAll('.setlist .track')
    allTracks.forEach(track => setList.push($(track).find('label').html()));
    if(setList.length < 10){
      $('.alert').html(`Please add ${10 - setList.length} more tracks.`)
    } else if(setList.length > 15){
      $('.alert').html(`Song limit reached! Please remove ${setList.length - 15} tracks.`)
    } else{
      $('.alert').html(``)
      setlistSuccess(setList)
    }
  });
  const setListTest = [
    'Really long Song name 01', 
    'Song name 02', 
    'Song name 03', 
    'You Make Me Wanna... (Ryan James Car', 
    'Song name 05', 
    'Nice & Slow (Ryan James Carr Remake)', 
    'Song name 07', 
    'Song name 08', 
    'You Make Me Wanna... (Ryan James Carr Remake - Instrumental)', 
    'Song name 10', 
    'Song name 11', 
    'Song name 12', 
    'Song name 13', 
    'really long reallyd long Sonme 09', 
    'Song name 015',
  ]

  function setlistSuccess(setlist){
    $('main').addClass('view-card')
    resizeCanvases();
    bindText(setlist);
  }

  // Canvas setup
  fabric.Object.prototype.set( {
    transparentCorners: false,
    borderColor: '#FFF',
    cornerColor: '#FFF',
    cornerSize: 30,
    cornerStyle: 'circle',
    lockUniScaling: true,
    minScaleLimit: 0.10,
    width: 635,
    height: 635
  } );

  const canvas = new fabric.Canvas( 'canvas', {
    backgroundColor: '#000',
    enableRetinaScaling: true,
    allowTouchScrolling: false,
    preserveObjectStacking: true,
    selection: false
  });

  function bindText(setlist) {
    let songCounter = 1
    let songPosition = 233
    let songPositionBottom = 580
    setlist.forEach(songTitle => {
      console.log(songTitle)
      let songFontSize = 0
      if(songTitle.length > 46){
        songFontSize = 9
      } else if(songTitle.length > 36){
        songFontSize = 12
      }else{
        songFontSize = 15
      }

      const song = new fabric.Textbox(`${songCounter}) ${songTitle.replace('&amp;','&')}`, {
        top: songCounter < 7 ? songPosition : songPositionBottom,
        left: 267,
        width: 450,
        breakWords: true,
        originX: 'center',
        originY: 'center',
        textAlign: 'center',
        fontSize: songFontSize,
        lineHeight: 1,
        fontFamily: 'aviano-future',
        fontWeight: 'bold',
        fill: '#ffffff',
        lockRotation: true,
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        splitByGrapheme: true,
        transparentCorners: true,
        cornerSize: 0,
        border: 0,
        autoCapitalize: 'on',
        asBorders: false,
        strokeWidth: 0,
        editable: false,
      })

      songPosition += 30
      if(songCounter >= 7){
        songPositionBottom += 33
      }
      songCounter++
      canvas.add(song);
    });
  }

  fabric.Image.fromURL( './dist/img/card.jpg', function ( img ) {
    img.set( {
      left: 0,
      top: 0,
      angle: 0,
      scaleX: canvas.width / img.width,
      scaleY: canvas.height / img.height,
      evented: false,
      selectable: false
    } );

    canvas.add( img );
    img.sendToBack();
  } );

  // Resize
  resizeCanvases();
  $( window ).on( 'resize', resizeCanvases );

  function resizeCanvases() {
    const parent_width = $( '.canvas-container-overflow' ).parent().width();
    const canvas_width = $( '.canvas-container' ).outerWidth();
    const canvas_height = $( '.canvas-container' ).outerHeight();
    if ( canvas_width > parent_width ) {
      const width_diff = canvas_width - parent_width;
      const scale_diff = width_diff / canvas_width;
      const scale_inverse = 1 - scale_diff;
      $( '.canvas-container' ).css( 'transform', 'scale3d(' + scale_inverse + ', ' + scale_inverse + ', ' + scale_inverse + ')' );
      $( '.canvas-container-overflow' ).css( 'height', canvas_height * scale_inverse );
    } else {
      $( '.canvas-container' ).css( 'transform', 'scale3d(1, 1, 1)' );
      $( '.canvas-container-overflow' ).css( 'height', 'auto' );
    }
  }

  $( '#btn-save' ).on( 'click', function ( e ) {
    e.preventDefault();
    const btn = $( this );
    const nonce = btn.attr( 'data-nonce' );
    const img = canvas.toDataURL( { format: 'jpeg', quality: 0.8 } );
    const a = document.createElement( 'a' );
    a.href = img;
    a.download = 'card.jpeg';
    a.click();
    a.remove();
  });

  // POPUPS
  $('.popup .close').click(function(){
    $('.popup').removeClass('active')
    $('.popup section').removeClass('active')
  });

  $('.popup-trigger').click(function(e){
    e.preventDefault()
    const target = $(this).attr('href')
    $(`.popup, ${target}`).addClass('active')
    if(target === '#apple'){
      // Create a new SMEAppleMusic object with your parameters.
      var sm = new SMEAppleMusic({
        ae: {
          ae: "8e9251bf5b18f366ee78d8b8882021478f2d11a9fe1e6797aeb22ae9ba21d169",
          brand_id: "3443145",
          segment_id: "2039690",
          activities: '{"actions":{"presave":131474},"mailing_list_optins":{"a0S2400000EPtK8EAL":131476}}'
        },
        am: {
          dev_token: "",
          save_mode: "library",
          custom_playlist_name: "",
          new_apple_presave: true,
          resources: {
            playlists: [],
            albums: []
          }
        },
        sf: {
          form: "391383",
          default_mailing_list: ""
        },
        smf: {
          campaign_id: "391383",
          campaign_key: "97c5231f0bf801fc76cc66aa88bf2ce1"
        }
      });
      document.getElementById("apple_music_form").addEventListener("submit", function (e) {
        e.preventDefault();
        // Grab the email address value.
        var email = document.getElementById("apple_music_email_address").value;
        // Pass the email address and opt in status
        // This can be true or false depending on if the user is forced into the list, or chooses via a checkbox or similar.
        sm.doActions(email, false, ["a0S2400000EPtK8EAL"], false, ["6383315;Certified_Welcome;"]).then(function (res) {
          // Complete - handle your logic here.
          $('.popup, .popup section').removeClass('active')
          document.location = document.location.href+"?state=thankyouapple"
        }).catch(function (err) {
        });
      });
    }
  });

  // spotify


  // NEWSLETTER
  $("#usher-set-list-generator-connect").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
        withCredentials: false
      },
      success: function (data) {
        $('.newsletter-alert').html('Thank you for subscribing!')
        $("#usher-set-list-generator-connect").hide()
      },
      error: function (err) {
        $('.newsletter-alert').html('Something went wrong, please try again.')
      }
    });
  });

  $('body').on('change', '.mailing-list-id', function () {
    $( "#ts-for-"+$(this).attr('id')).prop( "checked", $(this).is(':checked') );
  });

  $('.options').click(function(){
    $(this).toggleClass('active')
  });

  var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
    return false;
  };

  const param = getUrlParameter('state') 
  if (param == 'thankyouapple'){
    $('.popup, #thankyouapple').addClass('active')
  }
  if (param == 'thank-you' || param === 'thankyou' || param === 'success'){
    $('.popup, #thankyouspotify').addClass('active');
  }


  $('#edit').click(function(){
    $('.setlist').toggleClass('reorder')
    if($(this).html() === 'Change Order'){
      $(this).html('Save Order')
    }else{
      $(this).html('Change Order')
    }
  });

  $('.setlist').on('click', '.up', function() {
    $(this).parent().parent().insertBefore($(this).parent().parent().prev())
  })
  
  $('.setlist').on('click', '.down', function() {
    $(this).parent().parent().insertAfter($(this).parent().parent().next())
  })

});